body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeslider .slick-dots li button:before {
	color: #000000 !important; /* Aap apni pasand ka color yahan set karein */
  }
  
  /* Active dot ka color change karein */
  .homeslider .slick-dots li.slick-active button:before {
	color: #ffffff !important; /* Active dot ke liye color */
  }


  .footerContainerms {
    position: fixed;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .06);
    /* height: 60px; */
    padding: 0 10px;
    bottom: 0;
    z-index: 2;
    align-items: center;
	max-width:393px;
	width: 100%;
    /* display: flex
; */
    background: #36474F;
}
.successorder {
	align-items: center;
	height: 100vh;
	text-align: center;
  }
  
  .successorder img {
	max-width: 300px;
	margin: auto;
  }
  

.btn-xs {
    font-size: 11px !important;
    padding: 5px !important;
	margin-left: 5px !important;
}
.rdbtn1{
	margin-right: 5px;
}

.wh-100{
	width: 100vh !important;
	height: 100vh;
}

.radio-inline{
	padding: 25px;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin: 10px;
}


		.MuiSvgIcon-root {
			fill: currentColor;
			width: 1em;
			height: 1em;
			display: inline-block;
			font-size: 1.5rem;
			transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			flex-shrink: 0;
			user-select: none;
		}

		.MuiSvgIcon-colorPrimary {
			color: #3f51b5;
		}

		.MuiSvgIcon-colorSecondary {
			color: #f50057;
		}

		.MuiSvgIcon-colorAction {
			color: rgba(0, 0, 0, 0.54);
		}

		.MuiSvgIcon-colorError {
			color: #f44336;
		}

		.MuiSvgIcon-colorDisabled {
			color: rgba(0, 0, 0, 0.26);
		}

		.MuiSvgIcon-fontSizeInherit {
			font-size: inherit;
		}

		.MuiSvgIcon-fontSizeSmall {
			font-size: 1.25rem;
		}

		.MuiSvgIcon-fontSizeLarge {
			font-size: 2.1875rem;
		}


  
		@-webkit-keyframes mui-auto-fill {}

		@-webkit-keyframes mui-auto-fill-cancel {}

		.MuiInputBase-root {
			color: rgba(0, 0, 0, 0.87);
			cursor: text;
			display: inline-flex;
			position: relative;
			font-size: 1rem;
			box-sizing: border-box;
			align-items: center;
			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
			font-weight: 400;
			line-height: 1.1876em;
			letter-spacing: 0.00938em;
		}

		.MuiInputBase-root.Mui-disabled {
			color: rgba(0, 0, 0, 0.38);
			cursor: default;
		}

		.MuiInputBase-multiline {
			padding: 6px 0 7px;
		}

		.MuiInputBase-multiline.MuiInputBase-marginDense {
			padding-top: 3px;
		}

		.MuiInputBase-fullWidth {
			width: 100%;
		}

		.MuiInputBase-input {
			font: inherit;
			color: currentColor;
			width: 100%;
			border: 0;
			height: 1.1876em;
			margin: 0;
			display: block;
			padding: 6px 0 7px;
			min-width: 0;
			background: none;
			box-sizing: content-box;
			animation-name: mui-auto-fill-cancel;
			letter-spacing: inherit;
			animation-duration: 10ms;
			-webkit-tap-highlight-color: transparent;
		}

		.MuiInputBase-input::-webkit-input-placeholder {
			color: currentColor;
			opacity: 0.42;
			transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputBase-input::-moz-placeholder {
			color: currentColor;
			opacity: 0.42;
			transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputBase-input:-ms-input-placeholder {
			color: currentColor;
			opacity: 0.42;
			transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputBase-input::-ms-input-placeholder {
			color: currentColor;
			opacity: 0.42;
			transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}

		.MuiInputBase-input:focus {
			outline: 0;
		}

		.MuiInputBase-input:invalid {
			box-shadow: none;
		}

		.MuiInputBase-input::-webkit-search-decoration {
			-webkit-appearance: none;
		}

		.MuiInputBase-input.Mui-disabled {
			opacity: 1;
		}

		.MuiInputBase-input:-webkit-autofill {
			animation-name: mui-auto-fill;
			animation-duration: 5000s;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder {
			opacity: 0 !important;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::-moz-placeholder {
			opacity: 0 !important;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:-ms-input-placeholder {
			opacity: 0 !important;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::-ms-input-placeholder {
			opacity: 0 !important;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:focus::-webkit-input-placeholder {
			opacity: 0.42;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:focus::-moz-placeholder {
			opacity: 0.42;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:focus:-ms-input-placeholder {
			opacity: 0.42;
		}

		label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input:focus::-ms-input-placeholder {
			opacity: 0.42;
		}

		.MuiInputBase-inputMarginDense {
			padding-top: 3px;
		}

		.MuiInputBase-inputMultiline {
			height: auto;
			resize: none;
			padding: 0;
		}

		.MuiInputBase-inputTypeSearch {
			-moz-appearance: textfield;
			-webkit-appearance: textfield;
		}



		.MuiInput-root {
			position: relative;
		}

		label+.MuiInput-formControl {
			margin-top: 16px;
		}

		.MuiInput-colorSecondary.MuiInput-underline:after {
			border-bottom-color: #f50057;
		}

		.MuiInput-underline:after {
			left: 0;
			right: 0;
			bottom: 0;
			content: "";
			position: absolute;
			transform: scaleX(0);
			transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
			border-bottom: 2px solid #3f51b5;
			pointer-events: none;
		}

		.MuiInput-underline.Mui-focused:after {
			transform: scaleX(1);
		}

		.MuiInput-underline.Mui-error:after {
			transform: scaleX(1);
			border-bottom-color: #f44336;
		}

		.MuiInput-underline:before {
			left: 0;
			right: 0;
			bottom: 0;
			content: "\00a0";
			position: absolute;
			transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			border-bottom: 1px solid rgba(0, 0, 0, 0.42);
			pointer-events: none;
		}

		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-bottom: 2px solid rgba(0, 0, 0, 0.87);
		}

		.MuiInput-underline.Mui-disabled:before {
			border-bottom-style: dotted;
		}

		@media (hover: none) {
			.MuiInput-underline:hover:not(.Mui-disabled):before {
				border-bottom: 1px solid rgba(0, 0, 0, 0.42);
			}
		}


    .main-area {
      background-color: transparent;
      overflow: hidden;
      width: 100%;
      min-width: 393px;
  }
    .resp-r{
      margin-left: 130px;
    }

		.MuiFormControl-root {
			border: 0;
			margin: 0;
			display: inline-flex;
			padding: 0;
			position: relative;
			min-width: 0;
			flex-direction: column;
			vertical-align: top;
		}

		.MuiFormControl-marginNormal {
			margin-top: 16px;
			margin-bottom: 8px;
		}

		.MuiFormControl-marginDense {
			margin-top: 8px;
			margin-bottom: 4px;
		}

		.MuiFormControl-fullWidth {
			width: 100%;
		}

.profileContainer{
	text-align: center;
}

.profileContainerChild{
	background: #FECF43;
	margin: 10px 15px;
	padding-top: 15px;
	color: #030303;
	padding-left: 10px;
	border-radius: 5px;
	text-align: left;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.sideNav-options{
	text-decoration: none;
	cursor: pointer;
}

.profileChildRight {
	background: #ffffff;
    color: #f44336;
    margin: 2px 8px;
    border-radius: 5px;
    padding: 2px;
    display: flex;
}

.profileChildRightbox1{
	width: 40%;
}
.profileChildRightbox2{
	width: 60%;
}

.profileChildRight a {
	font-size: 10px;
	color: #f44336;
	text-decoration: none;
	/* padding-right: 10px; */
}

.profileContainerChild h6{
font-size: 14px;
margin-bottom: 2px;
}
.profileContainerChild p{
font-size: 14px;
}

.profileContainer p{
	margin-bottom: 2px;
	/* color: #f44336; */
}
.profileContainer a{
	margin-bottom: 2px;
	color: #f44336;
	text-decoration: none;
}

.logoutBox {

	background: #f7d8d6;
	margin: 10px 15px;
	/* padding-top: 15px; */

	padding: 10px;
}
.logoutBox img{

width: 25px;
}

.logoutBox p{
	margin-bottom: 2px;
	color: #f44336;
	display: inline;
	
    padding-left: 20px;

}

.trList{
	text-align: left;
	font-size: 12px;
}
.trList2{
	text-align: left;
	font-size: 12px;
	margin-bottom: 1px;
}

.userList{
	border-bottom: 1px solid black;
	padding-bottom: 5px;
	padding-top: 8px;
}
.propayBox{
	margin: 20px;
	background: #ffffff;
	padding: 0px 0px 15px 0px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .06);
	border: 1px solid #20807417;
}
.btn-primary{
	background: #208074 !important;
}


.upibox {
	border: 1px solid #dee2e6;
	position: relative;
	padding: 7px;
}

/* .upibox2{
	position: absolute !important;
	right: 0;
} */
.topprob{
	text-align: center;
    color: #ffffff;
    background: #208074 !important;
}
.profileFirstBox{
	min-height: 100px;
}
.profileFirstBox p{
	font-size: 11px;
margin-bottom: 6px;
}
.profileFirstBox a{
margin-bottom: 1px;
background: green;
color: #ffffff;
padding: 6px;
text-decoration: none;
border-radius: 5px;
margin-top: 15px;
}
.MuiTypography-root{
	margin-bottom: 1px;
	margin-right: 5px;
}

.notificationicon{
	width: 20px;
	height: 20px;
	background: red;
	display: inline-block;
	border-radius: 50%;
}

.sendbtn{
	color: #ffffff;
    background-color: green;
    border-color: green;
	padding: 5px;
    font-size: 11px;
}

.wibtn{
	margin-top: 20px;
	/* background: #dc3545 !important; */
}
.wibtn a{
	background: #2727dd !important;
    width: 100% !important;
    display: block;

}

.box2{
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px;
	text-align: left;
}

.box2 p{
	margin-bottom: 1px;
	font-size: 12px;
}
.box2 h6{
	margin-bottom: 1px;
}

.box2 img {
	margin-top: 8px;
	margin-left: 17px;
}

.profileChildRight img {
	width: 20px;
}

.proimg{
	position: relative;
	margin-top: 13px;
}

.proimgv{
	position: absolute;
	top: 0;
	left: 0;
}

.filterBox{
	position: fixed;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .06);
    height: 60px;
    padding: 0 10px;
    top: 60px;
	max-width: 393px;
    width: 100%;
    z-index: 2;
    align-items: center;
    display: flex;
    background: #ffffff;
}

.flbv{
	position: absolute;
	right: 10px;

	top: -14px;
}

.flbvcon{
	position: relative;
	width: 100%;
}

.btnfil{

background: rgba(47, 47, 240, 0.713) !important;
color: #ffffff !important;

}

.btnfilActive{

	background: rgba(0, 0, 247, 0.713) !important;
	color: #ffffff !important;
}
.btnfild{

	background: rgba(5, 133, 5, 0.713) !important;
	color: #ffffff !important;
}
.socialmain {
    margin-top: 27px;
}

.socialmain a{
    width: 40px;
    height: 40px;

    display: inline-block;
 
    border: 1px solid rgb(4, 0, 255);
    border-radius: 50%;
	margin-left: 5px;
	text-align: center;
}
.socialmain img{
	width: 81%;
    padding: 4px;
	margin-top: 5px;

/* padding: 4px; */
}


.gametitle{
	font-size: 11px;
	margin-bottom: 5px;
}
.gametitle2{
	font-size: 13px;
    margin-bottom: 5px;
    color: #000000;
    background: #f64c4c57;
    padding: 10px 15px;
    border: 1px solid black;
    border-radius: 5px;
}
.gametitle3{
	font-size: 13px;
    margin-bottom: 5px;
    color: #000000;
    background: #8ff67757;
    padding: 10px 15px;
    border: 1px solid black;
    border-radius: 5px;
}
.gametitle4{
	font-size: 13px;
    margin-bottom: 5px;
    color: #000000;
    background: #7799f657;
    padding: 10px 15px;
    border: 1px solid black;
    border-radius: 5px;
}
.gametitle5{
	font-size: 13px;
    margin-bottom: 5px;
    color: #000000;
    background: #f6db7757;
    padding: 10px 15px;
    border: 1px solid black;
    border-radius: 5px;
}


.messagebox1 {
	padding: 10px 20px 10px 20px;

    border: 1px solid #e0e0e0;
    border-radius: 5px;
    align-items: center;
    display: flex;
	background: red;
	color: #ffffff;
}

.messagebox9 {
	padding: 10px 20px 10px 20px;

    border: 1px solid #f10000;
    border-radius: 5px;
    align-items: center;
    display: flex;
	/* background: #e1a1a1; */
	color: #ffffff;
}

.messagebox9 .collapseCard-text {
    color: #f10000;
    font-size: .8em;
    font-weight: 500;
    opacity: .9;
}
.messagebox2 {
	padding: 10px 20px 10px 20px;

    border: 1px solid #e0e0e0;
    border-radius: 5px;
    align-items: center;
    display: flex;
	background: rgb(16, 178, 35);
	color: #ffffff;
}
.w-90{
	width: 90%;
}

.messageboxtop {
	padding-top: 20px;
   
}
.installbtn {
	/* padding-top: 20px; */
	width: 100%;
   
}

.messagebox1 .collapseCard-text {
    color: #ffffff;
    font-size: .8em;
    font-weight: 500;
    opacity: .9;
}
.messagebox2 .collapseCard-text {
    color: #ffffff;
    font-size: .8em;
    font-weight: 500;
    opacity: .9;
}
.bg-blue{
	background: rgb(4, 0, 255);
}
.bg-blue:hover {
	/* background: rgb(4, 0, 255); */
	color: #e0e0e0;
}
.loginform {
    padding: 20px;
}
.lgin {
    background-color: #2284775c;
    border: 1px solid #828282;
    border-radius: 5px;
    padding: 40px 10px;
    color: #000000;
}


.loginform .input-group-text {
    /* display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap; */
    background-color: transparent;
	border: none;
    /* border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius); */
}

.loginform .form-control {
    border: none;
	outline: none !important;
	transition:none
}
.loginform .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}


.regud{
	font-size: 12px;
}

.profile-header {
	background-color: #1c1f24;
	color: #fff;
	padding: 30px 20px;
	text-align: center;
}
.profile-headernwmw {
	/* background-color: #1c1f24;
	color: #fff; */
	padding: 30px 20px;
	text-align: center;
}
.profile-header img {

	position: absolute;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	border: 5px solid #1c1f24;
	left: calc(50% - 40px);
	bottom: -40px;
}
.stats-card {
	background-color: #fff;
	border-radius: 10px;
	margin-top: -30px;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.stats-item {
	text-align: center;
	border-right: 1px solid #ddd;
}
.stats-item:last-child {
	border-right: none;
}
.stats-item h5 {
	font-weight: bold;
	margin: 0;
}
.stats-item p {
	margin: 0;
	color: #888;
}
.menu-item {
	background-color: #fff;
	padding: 15px;
	border-radius: 8px;
	margin-bottom: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.menu-item i {
	color: #007bff;
}
.menu-item span {
	font-size: 16px;
}
.toggle-switch {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.earn .card {
	border: none;
	border-radius: 15px;
	margin-bottom: 20px;
  }
  .earn  .card h5 {
	font-weight: bold;
  }
  .earn  .card p {
	margin-bottom: 0;
  }
  
  .earn  .card-container {
	border-radius: 15px;
	padding: 20px;
	color: white;
	text-align: center;
	margin-bottom: 15px;
	font-weight: bold;
  }
  .earn   .card-container h5 {
	margin-top: 10px;
	font-size: 18px;
  }
  .earn   .card-container p {
	font-size: 14px;
	margin: 5px 0 0;
  }
  .earn   .gradient-refer {
	background: linear-gradient(to right, #00c6ff, #0072ff);
  }
  .earn   .gradient-watch {
	background: linear-gradient(to right, #ff7eb3, #ff758c);
  }
  .earn   .gradient-lucky {
	background: linear-gradient(to right, #fbd786, #f7797d);
  }
  .earn   .gradient-buy {
	background: linear-gradient(to right, #43e97b, #38f9d7);
  }
  .earn   .gradient-play {
	background: linear-gradient(to right, #4facfe, #00f2fe);
  }

  .lottery-card {
	background-color: #ffffff;
	color: #2c3e50;
	border-radius: 10px;
	overflow: hidden;
	display: flex;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  .lottery-card img {
	width: 150px; /* Fixing the size of the image */
	height: 150px;
	object-fit: cover;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
  }
  .lottery-card .card-body {
	padding: 15px;
  }
  .lottery-card .title {
	font-weight: bold;
	font-size: 1.2rem;
  }
  .lottery-card .details span {
	font-weight: bold;
  }
  .lottery .nav-tabs .nav-link.active {
	background-color: #3498db;
	color: white;
	border-color: transparent;
  }
 .lottery .nav-tabs .nav-link {
	color: white;
  }

  .transaction-card {
	position: relative;
    border: 1px solid #ddd;
    border-radius: 15px;
    /* padding: 15px; */
    margin-bottom: 10px;
    background-color: #212529; /* Dark background color */
    color: #fff; /* White text color */
	overflow: hidden;
}

.trmt{
	padding: 15px;
	background: #2D2D2D;
}

.transaction-card .date {
    font-weight: bold;
	padding: 15px;
}

.transaction-card .amount {
    font-size: 18px;
    font-weight: bold;
}

.transaction-card .amount .coin {
    font-size: 14px;
    margin-left: 5px;
}

.transaction-card .status {
    font-size: 14px;
    margin-top: 10px;
    color: #ccc; /* Lighter text color */
}

.transaction-card .status.successful {
    color: #ffffff; 
	position: absolute;
	bottom: 0;
	right: 0;
	background: #4CAF50;
	padding: 5px;
}

.transaction-card .status.failed {
    color: #f44336; /* Red color for failed transactions */
}

.transaction-card .transaction-id {
    color: #ccc; /* Lighter text color */
}


.match-card {
	background: #ffffff;
	border-radius: 8px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
	margin-bottom: 20px;
	padding: 15px;
  }

  .match-title {
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 10px;
	color: #333;
  }

  .match-details {
	font-size: 0.9rem;
	color: #666;
	margin-bottom: 5px;
  }

  .badge-custom {
	background-color: #5cb85c;
	color: #fff;
	padding: 5px 10px;
	border-radius: 5px;
  }

  .btn-watch {
	background-color: #28a745 !important;
	color: white !important;
	font-weight: bold !important;
	width: 100%;
	margin-top: 10px;
  }

  .btn-joined {
	background-color: #f8f9fa !important;
	border: 1px solid #ced4da !important;
	color: #6c757d !important;
	font-weight: bold !important;
	width: 100%;
	margin-top: 10px;
  }

  .coin1 {
	color: #ff9800;
	font-weight: bold;
	font-size: 40px;
  }
  .coin {
	color: #ff9800;
	font-weight: bold;
  }

  .divider {
	border-bottom: 1px solid #ddd;
	margin-bottom: 10px;
  }

 .myWalletC{
	background: #fff;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	margin-top: 15px;
	height: 70vh;

 }



 .newlogin .login-card {
	max-width: 400px;
	margin: 50px auto;
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.newlogin .btn-primary {
	background-color: #6200EA;
	border: none;
}
.newlogin .btn-primary:hover {
	background-color: #4500b2;
}
.newlogin .btn-google {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
}
.newlogin .btn-google img {	
	width: 20px;
	height: 20px;
	margin-right: 10px;
}
.newlogin footer {
	font-size: 12px;
	text-align: center;
	margin-top: 20px;
}

.newregister  .register-card {
	max-width: 400px;
	margin: 50px auto;
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.newregister .register-card .btn-google {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
}
.newregister .register-card img {
	height: 50px;
	width: auto;
}
.newregister .btn-primary {
	background-color: #6200EA;
	border: none;
}
.newregister .btn-primary:hover {
	background-color: #4500b2;
}
.newregister .promo-section {
	display: flex;
	gap: 10px;
	align-items: center;
}
.newregister .promo-section input {
	flex-grow: 1;
}
.newregister footer {
	font-size: 12px;
	text-align: center;
	margin-top: 20px;
}

.solosd {
	display: inline-block;
	padding: 12px;
	border-radius: 5px;
	box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
	margin: 5px;
	font-size: 13px;
}

.secpr .profile-container {
	max-width: 400px;
	margin: 2rem auto;
	padding: 1.5rem;
	background-color: #2c2f38;
	border-radius: 10px;
  }

  .secpr  .profile-headersv {
	text-align: center;
  }

  .secpr  .profile-headersv img {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: #444;
	margin-bottom: 0.5rem;
  }

  .secpr  .profile-stats {
	display: flex;
	justify-content: space-around;
	margin: 1rem 0;
  }

  .secpr  .profile-stats div {
	text-align: center;
  }

  .secpr  .btn-purple {
	background-color: #6c5ce7;
	color: #fff;
  }

  .secpr  .btn-purple:hover {
	background-color: #5a4cb1;
  }